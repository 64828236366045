<template>
    <ValidationObserver ref="validator" slim>
        <ValidationProvider v-slot="{ errors }" rules="required|max:4">
            <v-text-field
                v-model="code"
                :label="$t('src\\views\\user\\confirmation.9701')"
                counter="4"
                outlined
                :error-messages="errors[0]"
                @keyup.enter="confirm"
            />
            <v-btn 
                color="primary"
                @click="confirm">{{ $t('src\\views\\user\\confirmation.8714') }}</v-btn>
        </ValidationProvider>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';

    export default {
        name: 'Registration',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            code: '',
        }),
        computed: {
            ...mapState('auth', ['user']),
        },
        methods: {
            isUserProfile() {
                if(this.user.firstname !== '' && this.user.lastname !== '' && this.user.avatar !== '') {
                    this.$router.push({ name: 'home' });
                } else {
                    this.$router.push({ name: 'user-profile-edit' });
                }
            },
            async confirm() {
                const valid = await this.$refs['validator'].validate();
                if(valid) {
                    await store.dispatch('auth/confirm', { code: this.code });
                    await store.dispatch('auth/fetch');
                    if(this.user.admin) {
                        this.$router.push({ name: 'home' });
                    } else {
                        await store.dispatch('auth/logout');
                        this.$router.push({ name: 'user-authorization' });
                    }
                }
            },
            
        }
    }
</script>

<style lang="scss">
.views-user-registration {
    padding: 24px;
    &__title {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 31px;
        color: #3A4256;
        margin: 24px 0;
    }
    &__sheet {
        position: absolute;
        top: 25%;
        right: 25%;
        display: flex;
    }
}

</style>
